import { Flex, Result, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as dd from 'dingtalk-jsapi'

// 获取微应用免登授权码
// https://open.dingtalk.com/document/orgapp/jsapi-request-auth-code
function JsapiAuth() {

  const navigate = useNavigate()

  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (dd.env.platform === 'notInDingTalk') {
      setErrorMsg('notInDingTalk')
    } else {
      setErrorMsg('requestAuthCode')
      dd.requestAuthCode({
        // clientId: 'dingwgdx6emlxr3fcrg8', // sales.mycht.cn
        clientId: 'dingl3jyntkazyg4coxf', // hub.mycht.cn
        corpId: 'ding48bce8fd3957c96b',
        success: (res) => {
          const { code } = res

          setErrorMsg(JSON.stringify(res))
          // navigate('/p/dingding/callback?state=global-saels&authCode=' + code, {
          //   replace: true,
          // })
        },
        fail: (error) => {
          setErrorMsg(JSON.stringify(error))
        },
        complete: () => {
          setErrorMsg('complete: ')
        }
      })
    }

  }, [])

  return (
    <Flex justify='center' align='center' gap='middle' vertical>
      <Result
        status='success'
        title='欢迎使用'
        subTitle={'正在获取钉钉登授权' + errorMsg}
        extra={[<Spin key='dingding-login' size='small' />]}
      />
    </Flex>
  )
}

export default JsapiAuth
